/* eslint-disable */
import request from '@/utils/request';

/**
 * Simple RESTful resource class
 */
class Resource {
  constructor(uri) {
    this.uri = uri;
  }
  //          in milliseconds
  list(query, override_timeout = undefined, override_responseType = undefined) {
    return request({
      url                  : '/api/' + this.uri,
      method               : 'get',
      params               : query,
      override_timeout     : override_timeout,
      override_responseType: override_responseType,
    });
  }
  listWeb(query) {
    return request({
      url: this.uri,
      method: 'get',
      params: query,
    });
  }
  listId(id, query) {
    return request({
      url: '/api/' + this.uri + '/' + id,
      method: 'get',
      params: query,
    });
  }
  get(id) {
    return request({
      url: '/api/' + this.uri + '/' + id,
      method: 'get',
    });
  }
  getNoId() {
    return request({
      url: '/api/' + this.uri,
      method: 'get',
    });
  }
  store(resource, override_timeout = undefined) {
    return request({
      url: '/api/' + this.uri,
      method: 'post',
      data: resource,
      override_timeout: override_timeout,
    });
  }
  update(id, resource, override_timeout = undefined) {
    return request({
      url: '/api/' + this.uri + '/' + id,
      method: 'put',
      data: resource,
      override_timeout: override_timeout,
    });
  }
  updateNoId(resource, override_timeout = undefined) {
    return request({
      url: '/api/' + this.uri,
      method: 'put',
      data: resource,
      override_timeout: override_timeout,
    });
  }
  destroy(id, override_timeout = undefined) {
    return request({
      url: '/api/' + this.uri + '/' + id,
      method: 'delete',
      override_timeout: override_timeout,
    });
  }
  destroyNoId(resource, override_timeout = undefined) {
    return request({
      url: '/api/' + this.uri,
      method: 'delete',
      data: resource,
      override_timeout: override_timeout,
    });
  }
}

export { Resource as default };
