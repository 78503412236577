import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Layout from "@/layout/Layout.vue";
import adminRoutes from "./modules/admin";
// import tariffmanagementRoute from "./modules/tariffmanagement";
import prospectRoutes from "./modules/prospect";
import automaticdebitsRoutes from "./modules/automaticDebits";
import renouvelementRoutes from "./modules/renouvelement";
import compareCA from "./modules/compareCA";
// import gestionNumFixe from "./modules/gestionNumFixe";
import stats from "./modules/stats";
// import externalLink from "./modules/external-link";  // exemple de lien externe apparaissant dans le menu de gauche

Vue.use(VueRouter);

// Liste des icons dispo pour la sidebar : https://icones.js.org/collection/ep
// et https://icones.js.org/ pour en télécharger d'autre à placer dans src/icons/svg/
export const constantRoutes = [
    {
        path: "/",
        name: "Accueil",
        redirect: "/dashboard",
        hidden: true,
        component: Layout,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: { title: "Accueil", icon: "tree-table", noCache: true },
                component: Home
            }
        ]
    },
    {
        path: "/profile/edit",
        component: Layout,
        // redirect: "/profile/edit",
        hidden: true,
        children: [
            {
                path: "",
                component: () => import("@/views/users/SelfProfile"),
                name: "SelfProfile",
                meta: { title: "Profil", icon: "user", noCache: true }
            }
        ]
    },
    {
        path: "/404",
        // redirect: { name: "Page404" },
        component: () => import("@/views/error-page/404"),
        hidden: true
    },
    {
        path: "/login",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login.vue")
    },

    // /!\/!\/!\/!\/!\/!\/!\/!\/!\/!\/!\
    // The wildcard "*" in this patern :
    // { path: "*", redirect: "/404", hidden: true }
    // seems to cause the impossibility to refresh a page or to enter a URL directly by hand.
    // After X tests, the wildcard "*" in the following form seems not to cause this behaviour... to see with time
    {
        path: '*',
        component: () => import("@/views/error-page/404"),
        hidden: true,
    },
];

/*
const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});
*/

// on met ici les routes/components soumis a permissions
export const asyncRoutes = [
    adminRoutes,
    // tariffmanagementRoute,
    prospectRoutes,
    automaticdebitsRoutes,
    renouvelementRoutes,
    compareCA,
    // gestionNumFixe,
    stats,
    // externalLink,
];

const createRouter = () =>
    new VueRouter({
        // mode: "history", // require service support
        mode: "history",
        scrollBehavior: () => ({ y: 0 }),
        base: "/",
        routes: constantRoutes
    });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;
